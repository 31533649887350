/* eslint-disable */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import RenewalDataService from "@/view/pages/wizard/renewal/pages/services/renewal.data.service";
import ClaimDataService from "@/view/pages/wizard/claim/pages/services/claim.data.service";
import CommonDataService from "@/view/pages/wizard/common/common.data.service";
import EndorsementDataService from "@/view/pages/wizard/endorsement/pages/services/endorsement.data.service";
import NewBusinessDataService from "@/view/pages/wizard/new-business/pages/services/new-business.data.service";
import moment from "moment";
import { scrollBehaviorMixin } from './mixins/scrollBehaviorMixin';

Vue.mixin(scrollBehaviorMixin);

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import { VBTooltip } from 'bootstrap-vue'
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import * as VeeValidate from "vee-validate";
import {
  required,
  email,
  oneOf,
  max_value,
  max,
  alpha_num,
  is,
} from "vee-validate/dist/rules";
import VueCurrencyFilter from "vue-currency-filter";
import VueCurrencyInput from "vue-currency-input";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.directive('b-tooltip', VBTooltip)


Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: { prefix: "$ " },
    allowNegative: false,
    distractionFree: false,
    precision: 2,
  },
});

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true,
});

VeeValidate.extend("totalPercentage", (value) => {
  if (value <= 100) {
    return true;
  }
  return "Total Percentage cannot exceed 100%";
});

VeeValidate.extend("max_value", {
  ...max_value,
  params: ["max"],
  message: "Invalid input",
});

VeeValidate.extend("max", {
  ...max,
  params: ["length"],
  message: "Maximum {length} characters allowed",
});

VeeValidate.extend("email", {
  ...email,
  message: "Invalid email address",
});

VeeValidate.extend("required", {
  ...required,
  message: "This field is required",
});

VeeValidate.extend("required-action", {
  ...required,
  message: "This action is required",
});

VeeValidate.extend("oneOf", {
  ...oneOf,
  message: "Choose one",
});

VeeValidate.extend("alpha_num", {
  ...alpha_num,
  message: "This field can only contain alphabets and numbers",
});

VeeValidate.extend("is", {
  ...is,
  params: ["value"],
  message: "Value must be {value}",
});

Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(VueSweetalert2);

Vue.use(VeeValidate);
Vue.component("ValidationProvider", VeeValidate.ValidationProvider);
Vue.component("ValidationObserver", VeeValidate.ValidationObserver);

// API service init
ApiService.init();

// Services used by various wizards - don't delete these lines
Vue.prototype.$newBusinessDataService = NewBusinessDataService;
Vue.prototype.$renewalDataService = RenewalDataService;
Vue.prototype.$commonDataService = CommonDataService;
Vue.prototype.$claimDataService = ClaimDataService;
Vue.prototype.$endorsementDataService = EndorsementDataService;

Vue.filter("dateParse", function (value) {
  if (!value) return "";
  return moment(value, "YYYY-MM-DD");
});

Vue.filter("dateFormat", function (value) {
  if (!value) return "";
  return value.format("DD/MM/YYYY");
});



new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
