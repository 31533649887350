import ApiService from "@/core/services/api.service";

const EndorsementDataService = {
  serverUrl: `${window.location.origin}/services/endorsement`,
  transactionId: null,
  userId: null,

  setServerUrl: function (url) {
    this.serverUrl = url;
  },

  init: function (transactionId, userId, token) {
    if (process.env.NODE_ENV === "development") {
      this.serverUrl = `http://localhost:3000/services/endorsement`;
    }

    this.transactionId = transactionId;
    this.userId = userId;
    ApiService.saveTokenAndSetHeader(token);
  },

  getWizardAppData: function () {
    return ApiService.get(
      `${this.serverUrl}/${this.transactionId}/wizard-app-data`,
      {
        params: { userId: this.userId },
      }
    );
  },

  saveWizardAppData: function (wizardAppData) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/wizard-app-data`,
      {
        appData: wizardAppData,
        userId: this.userId,
      }
    );
  },

  updateTransactionStatus: function (flagName, flagValue) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/status`, {
      flagName: flagName,
      flagValue: flagValue,
      userId: this.userId,
    });
  },

  updateTransactionData: function (data) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/update`, {
      transactionData: data,
      userId: this.userId,
    });
  },

  getQuoteDetails: function (quoteId) {
    return ApiService.get(`${this.serverUrl}/${this.transactionId}/quote`, {
      params: { userId: this.userId, quoteId: quoteId || "" },
    });
  },

  saveQuoteDetails: function (quote) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/quote`, {
      quote: quote,
      userId: this.userId,
    });
  },

  finaliseTransaction: function () {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/finalise`, {
      userId: this.userId,
    });
  },

  reopenTransaction: function (reason) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/reopen`, {
      userId: this.userId,
      reason: reason,
    });
  },
};

export default EndorsementDataService;
