import ApiService from "@/core/services/api.service";

const ClaimDataService = {
  serverUrl: `${window.location.origin}/services/claim`,
  claimId: null,
  userId: null,

  setServerUrl: function (url) {
    this.serverUrl = url;
  },

  getServerUrl: function () {
    return this.serverUrl;
  },

  init: function (claimId, userId, token) {
    if (process.env.NODE_ENV === "development") {
      this.serverUrl = `http://localhost:3000/services/claim`;
    }

    this.claimId = claimId;
    this.userId = userId;
    ApiService.saveTokenAndSetHeader(token);
  },

  getWizardAppData: function () {
    return ApiService.get(`${this.serverUrl}/${this.claimId}/wizard-app-data`, {
      params: { userId: this.userId },
    });
  },

  saveWizardAppData: function (wizardAppData) {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/wizard-app-data`,
      {
        appData: wizardAppData,
        userId: this.userId,
      }
    );
  },

  updateClaimData: function (data) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/update-claim`, {
      claimData: data,
      userId: this.userId,
    });
  },

  updateFollowUpDate: function (data) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/followup`, {
      Name: data.Name,
      K_FollowUpDate: data.K_FollowUpDate,
      K_FollowUpAction: data.K_FollowUpAction,
      userId: this.userId,
    });
  },

  createClaimForm: function () {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/initial-submit-claim-form`,
      {
        userId: this.userId,
      }
    );
  },

  disableClaimFormEditing: function (shortLink) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/disable-claim-form-editing`, {
      shortLink,
      userId: this.userId,
    });
  },

  addClaimContact: function (data) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/claim-contact`, {
      contact: data,
      userId: this.userId,
    });
  },

  saveClaimContacts: function (contacts) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/claim-contacts`, {
      contacts: contacts,
      userId: this.userId,
    });
  },

  saveLodgementStatus: function (data) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/lodgement`, {
      lodgementData: data,
      userId: this.userId,
    });
  },

  updateClaimDamage: function (data) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/claim-damage`, {
      damage: data,
      userId: this.userId,
    });
  },

  deleteClaimDamage: function (damageId) {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/delete-claim-damage`,
      {
        damageId: damageId,
        userId: this.userId,
      }
    );
  },

  updateClaimSettlement: function (data) {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/claim-settlement`,
      {
        settlement: data,
        userId: this.userId,
      }
    );
  },

  deleteClaimSettlement: function (settlementId) {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/delete-claim-settlement`,
      {
        settlementId: settlementId,
        userId: this.userId,
      }
    );
  },

  getReferenceData: function () {
    return ApiService.get(`${this.serverUrl}/${this.claimId}/ref-data`, {
      params: { userId: this.userId },
    });
  },

  updateClaimPolicy: function (policyId) {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/claim-policy`, {
      policyId: policyId,
      userId: this.userId,
    });
  },

  addClaimPolicy: function (data) {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/add-claim-policy`,
      {
        policy: data,
        userId: this.userId,
      }
    );
  },

  createClaimReport: function () {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/claim-finalisation/pdf`,
      {
        userId: this.userId,
      }
    );
  },

  finaliseClaim: function () {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/finalise`, {
      userId: this.userId,
    });
  },

  setIndemnityStatus: function (indemnityStatus, indemnityStatusDescription) {
    return ApiService.post(
      `${this.serverUrl}/${this.claimId}/indemnity-status`,
      {
        indemnityStatus: indemnityStatus,
        indemnityStatusDescription: indemnityStatusDescription,
        userId: this.userId,
      }
    );
  },

  reopenClaim: function () {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/reopen`, {
      userId: this.userId,
    });
  },

  calculateTotalClaimCost: function () {
    return ApiService.post(`${this.serverUrl}/${this.claimId}/claim-cost`, {
      userId: this.userId,
    });
  },
};

export default ClaimDataService;
