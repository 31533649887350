export const scrollBehaviorMixin = {
    beforeRouteLeave(to, from, next) {
        this.saveScrollPosition();
        next();
    },
    methods: {
        saveScrollPosition() {
            const scrollPosition = window.scrollY;
            sessionStorage.setItem('scrollPosition', scrollPosition.toString());
        },
        restoreScrollPosition() {
            const savedPosition = sessionStorage.getItem('scrollPosition');
            if (savedPosition) {
                window.scrollTo(0, parseInt(savedPosition, 10));
                sessionStorage.removeItem('scrollPosition'); // Clear after restoring
            }
        }
    }
};