import ApiService from "@/core/services/api.service";

const CommonDataService = {
  serverUrl: `${window.location.origin}/services`,
  transactionId: null,
  claimId: null,
  userId: null,

  setServerUrl: function (url) {
    this.serverUrl = url;
  },

  init: function (transactionId, userId, token) {
    if (process.env.NODE_ENV === "development") {
      this.serverUrl = `http://localhost:3000/services`;
    }

    this.transactionId = transactionId;
    this.userId = userId;
    ApiService.saveTokenAndSetHeader(token);
  },

  initClaim: function (claimId, userId, token) {
    if (process.env.NODE_ENV === "development") {
      this.serverUrl = `http://localhost:3000/services`;
    }

    this.claimId = claimId;
    this.userId = userId;
    ApiService.saveTokenAndSetHeader(token);
  },

  getNotes: function () {
    let url = null;
    if (this.claimId) {
      url = `${this.serverUrl}/${this.claimId}/notes`;
    } else {
      url = `${this.serverUrl}/${this.transactionId}/notes`;
    }
    return ApiService.get(url, {
      params: { userId: this.userId, isClaim: !!this.claimId },
    });
  },

  addNote: function (/*noteTitle,*/ noteText) {
    let url = null;
    if (this.claimId) {
      url = `${this.serverUrl}/${this.claimId}/note`;
    } else {
      url = `${this.serverUrl}/${this.transactionId}/note`;
    }
    return ApiService.post(url, {
      // noteTitle: noteTitle,
      noteText: noteText,
      isClaim: !!this.claimId,
      userId: this.userId,
    });
  },

  addTask: function (task) {
    let url = null;
    if (this.claimId) {
      url = `${this.serverUrl}/${this.claimId}/task`;
    } else {
      url = `${this.serverUrl}/${this.transactionId}/task`;
    }
    return ApiService.post(url, {
      task: task,
      isClaim: !!this.claimId,
      userId: this.userId,
    });
  },

  getActiveUsers: function () {
    return ApiService.get(`${this.serverUrl}/active-users`, {
      params: { userId: this.userId },
    });
  },

  discardTransaction: function (discardReason, discardDetails) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/discard`, {
      userId: this.userId,
      discardReason: discardReason,
      discardDetails: discardDetails,
    });
  },

  updateDeclarationReviewStatus(reviewed) {
    return ApiService.post(`${this.serverUrl}/${this.transactionId}/declaration-review`, {
      reviewed: reviewed,
      userId: this.userId,
    });
  },

  getNextTransactionInBatch: function (batch) {
    return ApiService.get(`${this.serverUrl}/batch-processing/next-txn`, {
      params: {
        userId: this.userId,
        transactionId: this.transactionId,
        batch: batch,
      },
    });
  },

  getCustomViews: function () {
    return ApiService.get(`${this.serverUrl}/custom-views`, {
      params: { userId: this.userId },
    });
  },

  searchPolicies: function (searchTerm) {
    return ApiService.get(`${this.serverUrl}/active-policies`, {
      params: { userId: this.userId, searchTerm: searchTerm },
    });
  },

  getUserBrand(userId) {
    return ApiService.get(`${this.serverUrl}/user/${userId}/brand`, {
      params: { userId: this.userId }
    });
  },

  getPolicyTransactions: function (policyId) {
    return ApiService.get(`${this.serverUrl}/active-transactions`, {
      params: { userId: this.userId, policyId: policyId },
    });
  },

  getCustomViewTransactions: function (customView) {
    return ApiService.get(`${this.serverUrl}/custom-view-transactions`, {
      params: { userId: this.userId, customView: customView },
    });
  },

  getEmailTemplate: function (templateName, additionalData = null) {
    let recordId = this.claimId ? this.claimId : this.transactionId;
    return ApiService.get(`${this.serverUrl}/create-email`, {
      params: {
        recordId: recordId,
        userId: this.userId,
        templateName: templateName,
        additionalData: additionalData ? JSON.stringify(additionalData) : null,
      },
    });
  },

  savePolicyAlert: function (alert) {
    return ApiService.post(
      `${this.serverUrl}/${this.transactionId}/policy-alert`,
      {
        policyAlert: alert,
        userId: this.userId,
      }
    );
  },

  saveWizardCurrentPage: function (pageNumber) {
    let url = null;
    let isClaim = false;
    if (this.claimId) {
      url = `${this.serverUrl}/${this.claimId}/wizard-page`;
      isClaim = true;
    } else {
      url = `${this.serverUrl}/${this.transactionId}/wizard-page`;
    }

    return ApiService.post(url, {
      pageNumber: pageNumber,
      userId: this.userId,
      isClaim: isClaim,
    });
  },

  getQuoteDetails: function (quoteId) {
    return ApiService.get(
      `${this.serverUrl}/${this.transactionId}/quotes/${quoteId}`,
      {
        params: { userId: this.userId },
      }
    );
  },
};

export default CommonDataService;
