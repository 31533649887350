import Vue from "vue";
import Router from "vue-router";
import { scrollBehaviorMixin } from './mixins/scrollBehaviorMixin';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: () => {
        // Redirect to CIB website (rather than showing a 404 page)
        window.location.href = "https://www.collectiveib.com.au";
      },

      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "renewal",
              name: "renewal",
              component: () =>
                import("@/view/pages/wizard/renewal/RenewalWizard.vue"),
            },
            {
              path: "endorsement",
              name: "endorsement",
              component: () =>
                import("@/view/pages/wizard/endorsement/EndorsementWizard.vue"),
            },
            {
              path: "newbusiness",
              name: "newbusiness",
              component: () =>
                import(
                  "@/view/pages/wizard/new-business/NewBusinessWizard.vue"
                ),
            },
            // {
            //   path: "transaction-search",
            //   name: "transaction-search",
            //   component: () =>
            //     import("@/view/pages/wizard/transaction-search/TransactionSearch.vue")
            // },
            {
              path: "claim",
              name: "claim",
              component: () =>
                import("@/view/pages/wizard/claim/ClaimWizard.vue"),
            },
            {
              path: "cancel",
              name: "cancel",
              component: () =>
                import(
                  "@/view/pages/wizard/cancellation/CancellationWizard.vue"
                ),
            },
          ],
        },
      ],
    },

    {
      name: "RenewalBrokerAdvice",
      path: "/renewal-broker-advice",
      component: () =>
        import("@/view/pages/RenewalBrokerAdvice/BrokerAdvice.vue"),
    },
    {
      name: "RenewalBrokerAdvicePDF",
      path: "/renewal-broker-advice-pdf",
      component: () =>
        import("@/view/pages/RenewalBrokerAdvice/BrokerAdvicePdf.vue"),
    },

    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});

router.afterEach((to, from) => {
  // Ensure the page has loaded
  Vue.nextTick(() => {
    scrollBehaviorMixin.methods.restoreScrollPosition();
  });
});

export default router;