import CommonDataService from '../../../view/pages/wizard/common/common.data.service';

export default {
  namespaced: true,
  state: {
    alertText: ''
  },
  mutations: {
    SET_ALERT_TEXT(state, text) {
      state.alertText = text;
    }
  },
  actions: {
    async updateAlertText({ commit }, text) {
      try {
        await CommonDataService.savePolicyAlert(text);
        commit('SET_ALERT_TEXT', text);
      } catch (error) {
        console.error('Failed to update policy alert:', error);
        throw error;
      }
    },
    setAlertText({ commit }, text) {
      commit('SET_ALERT_TEXT', text);
    }
  },
  getters: {
    getAlertText: state => state.alertText
  }
};
